<template>
  <b-modal
    id="coupon-detail"
    :visible="visible"
    size="lg"
    hide-footer
    centered
    @change="$emit('change', false)"
  >
    <template #modal-header>
      <div
        class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center"
      >
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h5 class="title">
            {{ $t("coupons.details") }}
          </h5>
          <div class="d-flex align-items-center">
            <!-- Selector Desktop -->
            <div class="mx-2 d-flex">
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="Edit2Icon"
                :title="$t('tooltips.crud.edit')"
                size="20"
                class="text-primary ml-1 pointe"
                @click="$emit('editDiscount', item)"
              />
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="Trash2Icon"
                :title="$t('tooltips.crud.delete')"
                size="20"
                class="text-primary ml-1 pointer"
                @click="$emit('deleteDiscount', item)"
              />
            </div>

            <button
              type="button"
              aria-label="Close"
              class="close order-0 order-lg-1"
              @click="closeModal"
            >
              ×
            </button>
          </div>
        </div>
      </div>
    </template>
    <b-row class="my-2 mx-1">
      <!--       <b-col
        cols="12"
        lg="5"
        class="d-flex aling-items-center justify-content-center"
      >
        <b-img
          :src="item.imageURL || placeholder"
          :alt="translate(item.name)"
          class="w-100"
          style="max-height: 375px"
        />
      </b-col> -->
      <!-- Name -->
      <b-col cols="12" lg="12">
        <h4 class="mb-2">
          {{ translate(item.name) }}
        </h4>
      </b-col>
      <!-- Apply type -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.modelType" cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.modelType") }}
            </small>
          </b-col>
          <b-col v-if="item.modelType" cols="8">
            <p>
              {{ item.modelType }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- Discount -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.discount") }}
            </small>
          </b-col>
          <b-col cols="8">
            <p>{{ item.discount }}{{ item.isPercentage ? "% " : item.coin }}</p>
          </b-col>
        </b-row>
      </b-col>
      <!-- Code -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.code" cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.code") }}
            </small>
          </b-col>
          <b-col v-if="item.code" cols="8">
            <p>
              {{ item.code }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- Roles -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.roles && item.roles.length > 0" cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.roles") }}
            </small>
          </b-col>
          <b-col
            v-if="item.roles && item.roles.length > 0"
            cols="8"
            class="zindex-1"
          >
            <span v-for="item in item.roles" :key="item.key"
              >{{ item.name }},
            </span>
          </b-col>
        </b-row>
      </b-col>
      <!-- Start Date -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.startDate" cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.startDate") }}
            </small>
          </b-col>
          <b-col v-if="item.startDate" cols="8" class="zindex-1">
            <p>
              {{ getDate(item.startDate) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- End Date -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.endDate" cols="4">
            <small>
              {{ $t("backoffice.discounts.detail.endDate") }}
            </small>
          </b-col>
          <b-col v-if="item.endDate" cols="8" class="zindex-1">
            <p>
              {{ getDate(item.endDate) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- Total -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.maxNumberOfUses" cols="4">
            <small>
              {{ $t("backoffice.discounts.total-title") }}
            </small>
          </b-col>
          <b-col v-if="item.maxNumberOfUses" cols="8" class="zindex-1">
            <p>
              {{ item.maxNumberOfUses }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- UserTimes -->
      <b-col cols="12" lg="6">
        <b-row>
          <b-col v-if="item.maxNumberOfUsesPerMember" cols="4">
            <small>
              {{ $t("backoffice.discounts.userTimes-title") }}
            </small>
          </b-col>
          <b-col v-if="item.maxNumberOfUsesPerMember" cols="8" class="zindex-1">
            <p>
              {{ item.maxNumberOfUsesPerMember }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- Description -->
      <b-col v-if="translate(item.description)" class="mt-2" cols="12">
        <h5>{{ $t("backoffice.discounts.detail.description") }}</h5>
        <p>{{ translate(item.description) }}</p>
      </b-col>
      <!-- QR to share -->
      <b-col v-if="showShare(item)" cols="12" class="mt-50">
        <h5>
          {{ $t("backoffice.discounts.detail.qr") }}
        </h5>
      </b-col>
      <b-col
        v-if="showShare(item)"
        class="d-flex flex-column align-items-center"
      >
        <img :src="link" style="width: 140px" />
        <span
          v-if="!isCopied"
          class="ml-50 pointer text-primary"
          @click="handleClipboardCopied()"
        >
          <feather-icon
            icon="CopyIcon"
            size="18"
            class="text-primary mr-25 mb-1"
          />
          {{ $t("backoffice.discounts.share") }}
        </span>
        <b-alert
          :show="isCopied"
          dismissible
          class="mb-0 w-100"
          variant="primary"
          @dismissed="isCopied = false"
        >
          {{ $t("share.clipboard-title") }}
        </b-alert>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";

export default {
  name: "DiscountDetail",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    qrUrl() {
      const slug = this.community.parentKey
        ? this.community.parentCollective.slug
        : this.community.slug;
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        this.community.subdomain || "app"
      ).replace("{slug}", slug);
      return `${url}/coupons-form/${this.item?.key}?type=${this.item.modelType}&slug=${this.community.slug}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl
      )}&choe=UTF-8`;
    },
  },

  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    closeModal() {
      this.$bvModal.hide("coupon-detail");
    },
    getDate(item) {
      const date = new Date(item);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        hour12: false,
        minute: "numeric",
      };
      return date.toLocaleString(this.currentLocale, options);
    },
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    showShare(item) {
      const communityType = this.community.mainType.toLowerCase();
      return item.modelType === "community" || item.modelType === communityType;
    },
  },
};
</script>

<style></style>
