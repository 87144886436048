<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Name -->
      <template #cell(fullname)="{ item }">
        <b-media
          class="d-flex align-items-center ml-25 mt-25 pointer"
          @click="openDetailModal(item)"
        >
          <div
            class="text-dark d-flex align-items-center mt-25 truncated-text"
            style="min-width: 180px"
          >
            <b-avatar
              size="32"
              :src="item.imageURL"
              variant="primary"
              class="mr-75"
            />
            <p v-if="item.name" class="mb-0">
              {{ translateTranslationTable(currentLocale, item.name) }}
            </p>
          </div>
        </b-media>
      </template>
      <!-- Column: Discount -->
      <template #cell(discount)="{ item }">
        <p class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50">
          <span v-if="item.discount">
            {{ item.discount }} <span v-if="item.isPercentage">%</span>
            <span v-else-if="!item.isPercentage && item.discountCoin">{{
              item.discountCoin.symbol
            }}</span>
            <span v-else>%</span>
          </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>
      <!-- Column: code -->
      <template #cell(code)="{ item }">
        <p
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          style="width: 100px"
        >
          <span v-if="item.code"> {{ item.code.toUpperCase() }} </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>
      <!-- Column: Model -->
      <template #cell(model)="{ item }">
        <p
          v-if="modelTypeOptions(item.modelType)"
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
        >
          {{ modelTypeOptions(item.modelType) }}
        </p>
        <span
          v-else
          class="align-text-top text-muted text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          >---</span
        >
      </template>
      <!-- Column: Role -->
      <template #cell(role)="{ item }">
        <div
          v-for="(role, index) in item.roles"
          v-show="item.roles.length > 0"
          :key="index"
          style="width: 130px"
        >
          <p
            v-if="role"
            class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          >
            {{ role.name }}
          </p>
        </div>
        <span
          v-show="!item.roles.length > 0"
          class="align-text-top text-muted text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          >---</span
        >
      </template>
      <!-- Column: StartDate -->
      <template #cell(start)="{ item }">
        <p
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50"
          style="width: 180px"
        >
          <span v-if="item.startDate"> {{ getDate(item.startDate) }} </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>

      <!-- Column: EndDate -->
      <template #cell(end)="{ item }">
        <p
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50"
          style="width: 150px"
        >
          <span v-if="item.endDate"> {{ getDate(item.endDate) }} </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>
      <!-- Column: maxNumberOfUses -->
      <template #cell(maxNumberOfUses)="{ item }">
        <p
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          style="width: 100px"
        >
          <span v-if="item.maxNumberOfUses"> {{ item.maxNumberOfUses }} </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>
      <!-- Column: maxNumberOfUsesPerMember -->
      <template #cell(maxNumberOfUsesPerMember)="{ item }">
        <p
          class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"
          style="width: 100px"
        >
          <span v-if="item.maxNumberOfUsesPerMember">
            {{ item.maxNumberOfUsesPerMember }}
          </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="item">
        <div class="mx-2 d-flex justify-content-end">
          <feather-icon
            v-if="showShare(item)"
            v-b-tooltip.hover.bottom
            icon="Share2Icon"
            :title="$t('backoffice.discounts.share')"
            size="20"
            class="text-primary pointer mt-50"
            @click="share(item)"
          />
          <feather-icon
            icon="Edit2Icon"
            size="20"
            class="text-primary ml-1 pointer mt-50"
            @click="editDiscount(item.item)"
          />
          <feather-icon
            v-b-tooltip.hover.bottom
            icon="Trash2Icon"
            :title="$t('unshare.tooltip')"
            size="20"
            class="text-primary ml-1 pointer mt-50"
            @click="deleteItem(item.item)"
          />
        </div>
      </template>
      <template #empty>
        <div class="horizontal-placeholder d-block">
          <div>
            <img :src="membersPlaceholder" class="d-block" />
          </div>
          <div>
            <p class="text-primary text-center">
              {{
                $t("available.message", {
                  itemName: $t("backoffice.discounts.title"),
                })
              }}
            </p>
          </div>
        </div>
      </template>
    </b-table>
    <b-modal
      id="invite-modal"
      v-model="isModalOpened"
      :title="$t('backoffice.discounts.share')"
      size="sm"
      hide-footer
      @hidden="isCopied = false"
    >
      <h4 class="text-center my-1">
        {{ $t("organizations.qr.share-title") }}
      </h4>
      <img
        :src="link"
        style="width: 250px"
        class="d-block mx-auto small-card"
      />
      <!--       <h4 class="text-center">
        {{ $t('backoffice.discounts.code') }}: {{ hexToDecimal }}
      </h4> -->
      <div class="text-center mt-2 mb-1">
        <b-button
          class="font-weight-bold"
          :variant="'primary'"
          @click="handleClipboardCopied()"
        >
          <span class="d-flex pb-25"
            ><feather-icon
              icon="CopyIcon"
              size="18"
              class="d-block mx-auto mr-1"
            /><span class="pl-50 pt-25">{{
              $t("share.clipboard-title")
            }}</span></span
          >
        </b-button>
      </div>
      <b-alert :show="isCopied" variant="primary" class="text-center">
        {{ $t("share.copied-message") }}
      </b-alert>
    </b-modal>
    <!-- Unshare modal -->
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="coupon"
      @remove-item="handleRemoveItem"
    />
    <discount-detail
      :key="key"
      v-model="isDiscountDetailVisible"
      :item="itemSelected"
      @editDiscount="editDiscount"
      @deleteDiscount="deleteItem"
    />
  </div>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { validatorEmailValidator } from "@/@core/utils/validations/validators";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { tryOnMounted } from "@vueuse/shared";
import DiscountDetail from "@/backoffice/components/DiscountDetail.vue";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";

export default {
  name: "DiscountsTable",
  components: {
    UnshareModal,
    DiscountDetail,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isVerified: false,
      currentDiscount: {},
      enableStatus: "",
      isModalOpened: false,
      isCopied: false,
      isUnshareModalVisible: false,
      isDiscountDetailVisible: false,
      itemToDelete: {},
      itemSelected: {},
    };
  },
  computed: {
    fields() {
      const fields = [
        {
          key: "fullname",
          label: "Nombre",
          sortable: true,
          stickyColumn: true,
        },
        {
          key: "code",
          label: "Codigo",
          sortable: true,
        },
        {
          key: "discount",
          label: this.$t("backoffice.discounts.discount-percent"),
          sortable: tryOnMounted,
        },
        {
          key: "model",
          label: this.$tc("backoffice.classifiers.model", 1),
          sortable: true,
        },
        {
          key: "role",
          label: "Roles",
          sortable: true,
        },
        {
          key: "start",
          label: this.$t("backoffice.discounts.start-date"),
          sortable: true,
        },
        {
          key: "end",
          label: this.$t("backoffice.discounts.end-date"),
          sortable: true,
        },
        {
          key: "maxNumberOfUses",
          label: this.$t("backoffice.discounts.total-title"),
          sortable: true,
        },
        {
          key: "maxNumberOfUsesPerMember",
          label: this.$t("backoffice.discounts.userTimes-title"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("backoffice.members.actions"),
          sortable: false,
        },
      ];
      return fields;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    community() {
      return this.$store.getters.currentCollective;
    },

    qrUrl() {
      const slug = this.community.parentKey
        ? this.community.parentCollective.slug
        : this.community.slug;
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        this.community.subdomain || "app"
      ).replace("{slug}", slug);
      return `${url}/coupons-form/${this.currentDiscount?.key}?type=${this.currentDiscount.modelType}&slug=${this.community.slug}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl
      )}&choe=UTF-8`;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    deleteItem(item) {
      this.itemToDelete = item;
      this.isUnshareModalVisible = true;
    },
    editDiscount(item) {
      this.$emit("editDiscount", item);
      this.isDiscountDetailVisible = false;
    },
    openDetailModal(item) {
      this.itemSelected = item;
      this.isDiscountDetailVisible = true;
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },

    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch("postItem", {
            type: "coupons/delete",
            requestConfig: {
              key: this.itemToDelete.key,
            },
          });
          this.$emit("fetchData");
          this.isDiscountDetailVisible = false;
          this.notifySuccess(this.$t("success-message.general-success-delete"));
        } catch {
          this.notifyError(this.$t("error-message.general-error"));
        }
      }
    },

    modelTypeOptions(value) {
      const models = {
        community: this.$t("backoffice.discounts.model-types.community"),
        subcommunity: this.$t("backoffice.discounts.model-types.subcommunity"),
        event: this.$t("backoffice.discounts.model-types.event"),
        course: this.$t("backoffice.discounts.model-types.course"),
        product: this.$t("backoffice.discounts.model-types.product"),
        service: this.$t("backoffice.discounts.model-types.service"),
        organization: this.$t("backoffice.discounts.model-types.organization"),
      };
      return models[value] || "";
    },

    getImageResource,
    validatorEmailValidator,
    getDate(item) {
      const d = new Date(item);
      return d.toLocaleString(this.currentLocale);
    },
    translateTranslationTable,
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    share(item) {
      this.isModalOpened = true;
      this.currentDiscount = item.item;
    },
    showShare(item) {
      const communityType = this.community.mainType.toLowerCase();
      return (
        item.item.modelType === "community" ||
        item.item.modelType === communityType
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
</style>
