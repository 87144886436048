<template>
  <backoffice-container>
    <b-card style="min-height: 80vh">
      <div>
        <div class="d-flex justify-content-between flex-wrap my-1">
          <h4 class="mb-1">
            {{ $t("backoffice.discounts.title") }}
          </h4>
          <b-button
            variant="primary"
            class="font-weight-bold"
            size="sm"
            @click="openModal"
          >
            {{ $t("backoffice.discounts.add") }}
          </b-button>
        </div>
        <div v-if="coupons.length > 0">
          <base-pagination-container
            class="members-list-container"
            :total="coupons.length"
            :loading="isLoading"
            :items="coupons"
            :per-page="perPage"
            :load-first-page="true"
            hide-pagination
            :search-placeholder="$t('backoffice.discounts.title')"
            @load-page="handleLoadPage"
          >
            <template #default="{ items, loading }">
              <discounts-table
                :members="items"
                :busy="loading"
                @editDiscount="openModal"
                @fetchData="getInitialData"
              />
            </template>
          </base-pagination-container>
        </div>
        <div
          v-else
          class="horizontal-placeholder d-flex flex-column flex-wrap justify-content-center align-items-center"
        >
          <img :src="placeholder" />
          <p class="text-primary">
            {{
              $t("backoffice.feedback.placeholder-text", {
                item: $t("backoffice.discounts.title"),
              })
            }}
          </p>
        </div>
      </div>
    </b-card>
    <discount-create-modal
      :discount="discountSelected"
      @fetchData="getInitialData"
    />
  </backoffice-container>
</template>

<script>
import BasePaginationContainer from "@core/components/containers/base/BasePaginationContainer.vue";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import DiscountsTable from "@/backoffice/components/DiscountsTable.vue";
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import vSelect from "vue-select";
import Service from "@/config/service-identifiers";
import FileUpload from "@core/components/files/FileUpload.vue";
import FilePreview from "@core/components/files/FilePreview.vue";
import { getImageResource } from "@/@core/utils/image-utils";
import { DateTime } from "luxon";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import DiscountCreateModal from "@/backoffice/components/DiscountCreateModal.vue";


export default {
  name: "Discounts",
  components: {
    BasePaginationContainer,
    AppScroll,
    DiscountsTable,
    BackofficeContainer,
    vSelect,
    FileUpload,
    FilePreview,
    DiscountCreateModal,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isLoading: false,

      discountSelected: null,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    coupons() {
      return this.$store.getters.coupons.unpaginated;
    },
    placeholder() {
      return Placeholder;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
  async created() {
    await this.getInitialData();
  },
  methods: {
    openModal(item) {
      this.discountSelected = {};
      this.discountSelected = { ...item };
      this.$bvModal.show("discount-create-modal");
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    async getInitialData() {
      try {
        await this.$store.dispatch("getItems", {
          itemType: "coupons",
          forceAPICall: true,
          perPage: 1000,
          requestConfig: {
            modelType: this.community.mainType?.toLowerCase(),
          },
        });
      } catch {
        this.notifyError(this.$t("error-message.load-failed"));
      }
    },
    async handleLoadPage({ page, search, isFilterOn = false }) {
      this.getInitialData();
    },
    async handleLoadPage({ page, search, isFilterOn = false }) {},

    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
</style>
