<template>
  <b-modal
    id="discount-create-modal"
    :title="form.key ? 'Editar descuento' : $t('backoffice.discounts.add')"
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="openModal"
    @hidden="reset"
  >
    <b-form @submit.prevent="form.key ? editDiscount() : createDiscount()">
      <b-form-group>
        <label for="name" class="font-weight-bold"
          >{{ $t("backoffice.discounts.name") }}*</label
        >
        <b-form-input
          id="name"
          v-model="form.name"
          :placeholder="$t('form-create-item.name-placeholder')"
          type="text"
        />
      </b-form-group>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="description" class="font-weight-bold">{{
              $t("backoffice.discounts.description")
            }}</label>
            <b-textarea
              id="description"
              v-model="form.description"
              :placeholder="$t('form-create-item.message-placeholder')"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="!form.automaticDiscount" cols="12" md="6">
          <b-form-group>
            <label for="code" class="font-weight-bold">{{
              $t("backoffice.discounts.code-discount")
            }}</label>
            <b-form-input
              id="code"
              v-model="form.code"
              :placeholder="$t('form.type-placeholder')"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          :cols="form.isPercentage ? '12' : '6'"
          :md="
            form.isPercentage
              ? !form.automaticDiscount
                ? '6'
                : '12'
              : !form.automaticDiscount
              ? '4'
              : '10'
          "
        >
          <b-form-group>
            <label
              for="percent"
              class="font-weight-bold d-flex justify-content-between"
              >{{
                form.isPercentage
                  ? $t("backoffice.discounts.discount-percent")
                  : "Descuento"
              }}
              *
              <div
                class="d-flex align-items-center pt-50"
                style="height: 17.4px"
              >
                <b-form-checkbox v-model="form.isPercentage" switch size="sm" />
                <p class="small font-small-2 mb-0 mt-n75">
                  {{ $t("backoffice.discounts.percentage") }} (%)
                </p>
              </div>
            </label>
            <b-form-input id="percent" v-model="form.discount" type="number" />
          </b-form-group>
        </b-col>
        <b-col v-if="!form.isPercentage" cols="6" md="2">
          <b-form-group>
            <label
              for="coin"
              class="font-weight-bold d-flex justify-content-between"
              >{{ $t("backoffice.discounts.coin") }}*
            </label>
            <b-form-select
              id="coin"
              v-model="selectedCurrency"
              :options="currencyCode"
              :placeholder="$t('form.type-placeholder')"
              label-type="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="model" class="font-weight-bold"
              >{{ $tc("backoffice.classifiers.model", 1) }}*</label
            >
            <v-select
              id="model"
              v-model="form.modelType"
              :searchable="false"
              :placeholder="$t('form.select-placeholder')"
              :options="modelTypeOptions"
              :reduce="(model) => model.value"
              :clearable="false"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="role" class="font-weight-bold"
              >{{ $t("backoffice.discounts.rol") }}*</label
            >
            <v-select
              id="role"
              v-model="selectedRoles"
              multiple
              :placeholder="$t('form.select-placeholder')"
              :options="roles"
              :reduce="(template) => template.key"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="percent" class="font-weight-bold"
              >{{ $t("calendar.meeting-form.start-date.label") }} ({{
                $t("events.event-details.local-time")
              }})</label
            >
            <VueCtkDateTimePicker
              v-model="form.startDate"
              :locale="locale"
              format="YYYY-MM-DD HH:mm"
              :label="$t('datepicker.select-label')"
              color="var(--primary)"
              no-button-now
              button-color="var(--primary)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="percent" class="font-weight-bold"
              >{{ $t("calendar.meeting-form.end-date.label") }} ({{
                $t("events.event-details.local-time")
              }})</label
            >
            <VueCtkDateTimePicker
              v-model="form.endDate"
              :locale="locale"
              format="YYYY-MM-DD HH:mm"
              :label="$t('datepicker.select-label')"
              color="var(--primary)"
              no-button-now
              button-color="var(--primary)"
              :min-date="form.startDate"
            />
          </b-form-group>
        </b-col>
        <!--           <b-col cols="12">
            <b-form-group :label="$t('backoffice.onboarding-form.form.image')" label-for="image" class="d-inline-block w-100">
              <file-preview
                v-if="form.imageURL"
                :src="getImageResource(form.imageURL)"
                mime-type="image"
                cancelable
                @cancel="form.imageURL = null"
              />
              <file-upload
                v-else
                v-model="image"
                type="image"
                cancelable
                :placeholder="$t('form-create-item.image-button')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="image = null"
              />
            </b-form-group>
          </b-col> -->
        <!-- maxNumberOfUses -->
        <b-col v-if="!form.automaticDiscount" cols="12" md="6">
          <b-form-group>
            <label for="maxNumberOfUses" class="font-weight-bold">{{
              $t("backoffice.discounts.total")
            }}</label>
            <b-form-input
              id="maxNumberOfUses"
              v-model="form.maxNumberOfUses"
              :placeholder="$t('backoffice.discounts.unlimited')"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- maxNumberOfUsesPerMember -->
        <b-col v-if="!form.automaticDiscount" cols="12" md="6">
          <b-form-group>
            <label for="user-times" class="font-weight-bold">{{
              $t("backoffice.discounts.userTimes")
            }}</label>
            <b-form-input
              id="user-times"
              v-model="form.maxNumberOfUsesPerMember"
              :placeholder="$t('backoffice.discounts.unlimited')"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- Automatic -->
        <!--         <b-col cols="12">
            <b-form-group label-for="automatic" class="mt-1">
              <b-form-checkbox
                id="automatic"
                v-model="form.automaticDiscount"
              >
                {{ $t('backoffice.discounts.automatic') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col> -->
        <!-- Accumlatable -->
        <!--         <b-col cols="12">
            <b-form-group label-for="accumulatable">
              <b-form-checkbox
                id="accumulatable"
                v-model="form.accumulatableDiscount"
              >
                {{ $t('backoffice.discounts.accumulatable') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col> -->
      </b-row>
      <b-alert
        v-if="showExistingCode"
        show
        variant="danger"
        class="m-0 error-notification"
      >
        {{ $t("backoffice.discounts.existingCode-alert") }}
      </b-alert>
      <b-alert
        v-if="showDiscountRequired"
        show
        variant="danger"
        class="m-0 error-notification"
      >
        {{ $t("backoffice.discounts.discountRequired-alert") }}
      </b-alert>
      <b-button
        variant="primary"
        class="float-right mt-2"
        :disabled="isSaving || isDisabled"
        type="submit"
      >
        <span v-if="isSaving" class="d-flex">
          <b-spinner class="mr-1" small variant="white" label="Loading..." />
          {{ $t("form.actions.save") }}
        </span>
        <span v-else>{{ $t("form.actions.save") }}</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import Service from "@/config/service-identifiers";
import FileUpload from "@core/components/files/FileUpload.vue";
import FilePreview from "@core/components/files/FilePreview.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { DateTime } from "luxon";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  components: {
    vSelect,
    FileUpload,
    FilePreview,
  },
  mixins: [ToastNotificationsMixin],

  props: {
    discount: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSaving: false,
      page: 1,
      form: {},
      initForm: {
        name: "",
        description: null,
        discount: null,
        startDate: null,
        code: "",
        endDate: null,
        discountType: "qr",
        imageSrc: null,
        maxNumberOfUses: null,
        maxNumberOfUsesPerMember: null,
        isPercentage: true,
        modelType: "community",
        automaticDiscount: false,
        accumulatableDiscount: false,
      },
      showExistingCode: false,
      showDiscountRequired: false,
      currencyCode: [{ value: null, text: "Select a Currency..." }],
      rolesItems: [],
      selectedRoles: [],
      selectedCurrency: 45,
      exclusive: false,
      image: null,
    };
  },
  computed: {
    modelTypeOptions() {
      const typeOptions = [
        "community",
        "subcommunity",
        "event",
        "course",
        "service",
        "project",
        "section",
        "challenge",
        "product",
        "organization",
      ];
      const options = [];
      typeOptions.forEach((item) =>
        options.push({
          name: this.$t(`backoffice.discounts.model-types.${item}`),
          value: item,
        })
      );
      return options;
    },
    isDisabled() {
      return this.form.name === "" || this.form.discount === null;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    roles() {
      if (this.$store.getters.roles) {
        const roles = this.$store.getters.roles.unpaginated;
        this.rolesItems = [];
        for (const indexRole in roles) {
          const newValue = {
            value: roles[indexRole].key,
            text: roles[indexRole].name,
            ...roles[indexRole],
          };
          this.rolesItems.push(newValue);
        }
        return this.rolesItems;
      }
      return [];
    },
  },
  async created() {
    // await this.getInitialData();
    await this.fetchCode();
    await this.getRoles();
  },
  methods: {
    openModal() {
      this.form = {};
      if (this.discount?.key) {
        this.form = { ...this.discount };
        this.selectedRoles = [];
        if (this.form.roles) {
          this.form.roles.forEach((role) => {
            this.selectedRoles.push(role.key);
          });
        }
        this.form.name = this.translate(this.form.name);
        this.form.description = this.translate(this.form.description);
        this.selectedCurrency = this.form.discountCoin || 45;
        this.form.startDate
          ? (this.form.startDate = DateTime.fromMillis(
              this.form.startDate
            ).toISO())
          : "";
        this.form.endDate
          ? (this.form.endDate = DateTime.fromMillis(this.form.endDate).toISO())
          : "";
      } else {
        this.form = { ...this.initForm };
        this.selectedRoles = [];
        if (this.roles.length > 0) {
          this.roles.forEach((role) => {
            this.selectedRoles.push(role.key);
          });
        }
        this.selectedCurrency = 45;
        this.form.modelType = this.modelTypeOptions[0]?.value;
      }
    },
    reset() {
      this.$bvModal.hide("discount-create-modal");
      this.showExistingCode = false;
      this.showDiscountRequired = false;
      this.form = { ...this.initForm };
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async editDiscount() {
      const name = {};
      name[this.locale] = this.form.name;
      const description = {};
      description[this.locale] = this.form.description;

      try {
        const file = {};
        const itemDelete = {};

        if (this.image) {
          file.imageURL = this.image;
        } else if (this.form.imageURL !== null) {
          file.imageURL = this.form.imageURL;
        } else {
          file.imageURL = null;
          itemDelete.deleteBgURL = true;
        }
        console.log(typeof this.form.maxNumberOfUses);
        const response = await this.$store.dispatch("editItem", {
          item: {
            itemType: "coupons",
            requestConfig: {
              key: this.form.key,
              couponKey: this.form.key,
              modelType: this.form.modelType,
              name,
              description,
              code: this.form.code,
              discount: this.form.discount,
              startDate: this.form.startDate,
              endDate: this.form.endDate,
              discountType: this.form.discountType,
              roleKeys: this.selectedRoles,
              discountCoin: this.selectedCurrency,
              isPercentage: this.form.isPercentage,
              automaticDiscount: this.form.automaticDiscount,
              accumulatableDiscount: this.form.accumulatableDiscount,
              maxNumberOfUses:
                this.form.maxNumberOfUses == null ||
                this.form.maxNumberOfUses === "" ||
                this.form.maxNumberOfUses === 0
                  ? null
                  : +this.form.maxNumberOfUses,
              maxNumberOfUsesPerMember:
                this.form.maxNumberOfUsesPerMember === null ||
                this.form.maxNumberOfUsesPerMember === "" ||
                this.form.maxNumberOfUsesPerMember === 0
                  ? null
                  : +this.form.maxNumberOfUsesPerMember,
              ...itemDelete,
            },
          },
          file,
        });
        this.notifySuccess(this.$t("backoffice.discounts.messages.success"));
        this.discount = response.data;
        this.$emit("fetchData");
        this.reset();
      } catch {
        this.notifyError(this.$t("backoffice.discounts.messages.error"));
      }
    },
    async createDiscount() {
      this.showExistingCode = false;
      this.showDiscountRequired = false;
      const name = {};
      name[this.locale] = this.form.name;
      const description = {};
      description[this.locale] = this.form.description;
      const requestConfig = {
        modelType: this.form.modelType,
        name,
        description,
        code: this.form.code,
        discount: this.form.discount,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        discountType: this.form.discountType,
        roleKeys: this.selectedRoles,
        discountCoin: this.selectedCurrency,
        isPercentage: this.form.isPercentage,
        automaticDiscount: this.form.automaticDiscount,
        accumulatableDiscount: this.form.accumulatableDiscount,
        maxNumberOfUses:
          this.form.maxNumberOfUses == null ||
          this.form.maxNumberOfUses === "" ||
          this.form.maxNumberOfUses === 0
            ? null
            : +this.form.maxNumberOfUses,
        maxNumberOfUsesPerMember:
          this.form.maxNumberOfUsesPerMember === null ||
          this.form.maxNumberOfUsesPerMember === "" ||
          this.form.maxNumberOfUsesPerMember === 0
            ? null
            : +this.form.maxNumberOfUsesPerMember,
      };
      try {
        const response = await this.$store.dispatch("createItem", {
          item: {
            itemType: "coupons",
            requestConfig,
          },
          file: this.image,
        });
        if (response.status === 200) {
          this.notifySuccess(this.$t("backoffice.discounts.messages.success"));
          this.$emit("fetchData");
          this.reset();
        }
        if (response.status === 400) {
          if (response.data) {
            if (response.data.existingCode) {
              this.showExistingCode = true;
            } else {
              this.showDiscountRequired = true;
            }
          }
        }
      } catch (error) {
        this.notifyError(this.$t("backoffice.discounts.messages.error"));
      }
    },
    async fetchCode() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "currencies",
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
            forceAPICall: true,
          },
        }
      );
      const currencyArray = response.data.data;
      for (const indexCurrency in currencyArray) {
        const newCurrency = {
          value: currencyArray[indexCurrency].id,
          text: `${currencyArray[indexCurrency].ISO} (${currencyArray[indexCurrency].symbol})`,
          ...currencyArray[indexCurrency],
        };
        this.currencyCode.push(newCurrency);
      }
    },
    async getRoles() {
      await this.$store.dispatch("getRoles", {
        communitySlug: this.$store.getters.currentCollective.slug,
        page: 1,
        perPage: 200,
      });
    },
  },
};
</script>

<style></style>
